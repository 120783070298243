
import UIStarter from "./src/ui_starter";

function start(){ 
	
	
	
	UIStarter();
	
}

start();